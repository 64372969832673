import { get, post } from 'src/utils/request'

const api_v1 = '/newretail'
const api_v2 = '/newretail/v2'
const api_v3 = '/newretail/v3'

// 刷新token
export const getRefreshToken = post(api_v1 + '/api/oauth/refreshToken')
//请求树组织
export const orgShopTree = post(api_v1 + '/api/current/orgShopTree')
export const getOrgShopTree = get(api_v1 + '/api/current/orgShopTree')
//摄像头接口---begin
//摄像头查询原设备-ID是否存在
const IsseqidExit = api_v1 + '/api/camera/isseqidexsit'
//摄像头查询MAC-ID是否存在
const IsmacidExit = api_v1 + '/api/camera/ismacidexsit'
//摄像头查询商户列表
const groupList = api_v1 + '/api/group/getlist'
//新增摄像头
const cameraAdd = api_v1 + '/api/camera/add'
//摄像头查询指定设备
const getSeq = api_v1 + '/api/camera/getseq'
//摄像头设置指定设备
const updateSeq = api_v1 + '/api/camera/updateseq'
//查询设备列表
export const getseqList = get(api_v1 + '/api/camera/getseqlist')
//使用管理查询设备列表
const getoptlist = api_v1 + '/api/camera/getoptlist'
//使用管理查询指定配置信息
// const getoptCamera = api_v1 + '/api/camera/getopt';
export const getoptCamera = post(api_v1 + '/v2/api/camera/getLiveInfo')
//使用管理设置指定配置信息
export const updateopt = post(api_v1 + '/api/camera/updateopt')
//查询摄像头在线状态
export const getonlinestatus = post(api_v1 + '/api/camera/getonlinestatus')
//盒子解除绑定
export const unbindCamera = post(api_v1 + '/api/camera/unbind')
//设置归属
export const setBelonging = post(api_v1 + '/api/camera/setBelonging')
//查询摄像头品牌列表
export const getCameraVendorList = post(api_v1 + '/api/agent/getCameraVendorList')
//修改盒子登录密码
export const updateAgentCurrentAuth = post(api_v1 + '/api/agent/updateAgentCurrentAuth')

//探针管理接口---begin
//摄像头查询原设备-ID是否存在
export const probeIsseqidExit = post(api_v1 + '/api/probe/issdevidexsit')
//摄像头查询MAC-ID是否存在
export const probeIsmacidExit = post(api_v1 + '/api/probe/ismacidexsit')
//新增摄像头
export const addprobe = post(api_v1 + '/api/probe/addprobe')
//摄像头查询指定设备
export const getprobedevinfo = post(api_v1 + '/api/probe/getprobedevinfo')
//摄像头设置指定设备
export const setprobedevinfo = post(api_v1 + '/api/probe/setprobedevinfo')
//查询设备列表
export const getprobedevlist = post(api_v1 + '/api/probe/getprobedevlist')
//使用管理查询设备列表
export const getProbeUseList = post(api_v1 + '/api/probe/getprobeuselist')
//使用管理设置设备
export const setProbeInfo = post(api_v1 + '/api/probe/setProbeInfo')
//查询盒子列表
const getAgentList = api_v1 + '/api/agent/getagentlist'
//配置盒子第一步
const getAgent = api_v1 + '/api/agent/getagent'
//配置盒子第一步
const updateAgent = api_v1 + '/api/agent/updateagent'
//配置盒子第2步查询列表
const getCameraList = api_v1 + '/api/agent/getcameralist'
//配置盒子第2步绑定摄像头
const bindCamera = api_v1 + '/api/camera/bindtoagent'
//摄像头库存列表
export const getStocklist = post(api_v1 + '/api/camera/getstocklist')
//摄像头库存——检测在线
const checkSeq = api_v1 + '/api/camera/checkseq'
//摄像头库存——查询配置模板
const getConfigTemplate = api_v1 + '/api/camera/getconfigtemplate'
//摄像头库存——点此检测
const saveCheckConfig = api_v1 + '/api/camera/savecheckconfig'
//摄像头库存——保存检测结果
const saveCheckResult = api_v1 + '/api/camera/savecheckresult'
//摄像头运行——查设置
const getMngConfig = api_v1 + '/api/camera/getmngconfig'
//摄像头运行——重置推流地址
const InitStreamUrl = api_v1 + '/api/camera/initstreamurl'
//摄像头管理——点此检测
const saveMngConfig = api_v1 + '/api/camera/savemngconfig'
//摄像头运行——列表
export const getMngList = post(api_v1 + '/v2/api/camera/getmnglist')
//摄像头运行——查询指定信息
export const getMngInfo = post(api_v1 + '/api/camera/getmnginfo')

// 客户管理接口——begin
// 查询客户列表
export const queryCustomerList = post(api_v1 + '/api/customer/queryUserList')
// 新增用户
export const addUserList = post(api_v1 + '/api/customer/addUserList')
// 模糊搜索用户
export const fuzzySearch = get(api_v1 + '/api/customer/fuzzyQueryUserList')
// 更新用户
export const updateUserList = post(api_v1 + '/api/customer/updateUser')
// 用户列表查询
export const queryCustomerManageList = get(api_v1 + '/api/customer/queryList')
export const removeUser = post(api_v1 + '/api/customer/removeUser')
const getGroupList = api_v1 + '/api/group/getGroupList'
// 新增客户
const addGroup = api_v1 + '/api/group/add'
// 查询客户
const getGroupInfo = api_v1 + '/api/group/getInfo'
// 查询客户
const editGroupInfo = api_v1 + '/api/group/save'
// 查询指定客户下待分配角色的用户
const getUserRoleListByCid = api_v1 + '/api/usercp/getUserRoleListByCid'
// 保存指定客户下待分配角色的用户
const updateUserRoleListByCid = api_v1 + '/api/usercp/updateUserRoleListByCid'

// 用户管理接口——begin
// 查询用户户列表
const getUserList = api_v1 + '/api/usercp/getList'
export const queryUserList = get(api_v1 + '/api/user/queryUserList')
export const updateUserPwd = post(api_v1 + '/api/user/changePassword')
// 停用启用账户
export const updateUserStatus = post(api_v1 + '/api/usercp/updateStatus')
// 手机号查重
const isAvailableMobile = api_v1 + '/api/current/isAvailableMobile'
// 查询各平台角色
export const getPlatRoles = post(api_v1 + '/api/usercp/getPlatRoles')
// 新增用户
export const addUser = post(api_v1 + '/api/usercp/add')
// 修改用户
export const updateUser = post(api_v1 + '/api/usercp/update')
// 修改操作查询指定用户
export const getUsercpInfo = post(api_v1 + '/api/usercp/getinfo')
// 查看指定用户
const getUserInfo = api_v1 + '/api/user/info'
// 查看页面查询各平台角色
const getPlatroleList = api_v1 + '/api/user/getplatrolelist'
// 查询用户组
export const getEnableUserGroupList = post(api_v1 + '/api/usercp/getEnableUserGroupList')

// 查询用户组列表
const getUserGroupList = api_v1 + '/api/userGroup/getList'
// 停用、启用用户组
const updateUserGroupStatus = api_v1 + '/api/userGroup/updateStatus'
// 查询用户组的备选用户列表
const getEnableUserList = api_v1 + '/api/userGroup/getEnableUserList'
// 新建用户组
const addUserGroup = api_v1 + '/api/userGroup/add'
// 修改用户组
const updateUserGroup = api_v1 + '/api/userGroup/update'
// 查询指定用户组
const getUserGroupInfo = api_v1 + '/api/userGroup/getInfo'

// 蓝牙发射器接口——begin
// 查询蓝牙列表
export const getBluetoothList = post(api_v1 + '/api/usercp/bluetooth/getList')
// 查询客户列表
export const getCusList = post(api_v1 + '/api/nrcp/getCusList')
// 查询现任用户
const getCurrentCus = api_v1 + '/api/current/getCurrentCus'
// 查询指定蓝牙
export const getBlueInfo = post(api_v1 + '/api/usercp/bluetooth/getInfo')
// 修改蓝牙
export const updateBluetooth = post(api_v1 + '/api/usercp/bluetooth/update')
// 新增蓝牙
export const addBluetooth = post(api_v1 + '/api/usercp/bluetooth/add')
// 查询蓝牙接收器列表
export const getReceiverList = post(api_v1 + '/api/usercp/bluetooth/getReceiverList')
// 查询指定蓝牙接收器
export const getReceiverInfo = post(api_v1 + '/api/usercp/bluetooth/getReceiver')
// 新增蓝牙接收器
export const addReceiver = post(api_v1 + '/api/usercp/bluetooth/addReceiver')
// 修改蓝牙接收器
export const updateReceiver = post(api_v1 + '/api/usercp/bluetooth/updateReceiver')

// CPU温度监控
export const getCpuTempList = get(api_v1 + '/api/cpuTemp/queryList')
export const queryCpuDetail = get(api_v1 + '/api/cpuTemp/queryDetail')

// 盒子升级
export const upgrade = {
  //查询盒子列表
  getAgentList: post(api_v2 + '/api/agent/getagentlist'),
  //配置盒子第一步
  getAgent: post(api_v2 + '/api/agent/getagent'),
  //配置盒子第一步
  updateAgent: post(api_v2 + '/api/agent/updateagent'),
  //配置盒子第2步查询列表
  getCameraList: post(api_v2 + '/api/agent/getcameralist'),
  //配置盒子第2步绑定摄像头
  bindCamera: post(api_v2 + '/api/camera/bindtoagent'),
  //盒子解除绑定
  unbindCamera: post(api_v2 + '/api/camera/unbind'),
  //查询某个广告机信息
  getADBoxInfo: get(api_v1 + '/api/box/getADBoxInfo'),
  //
  replaceCamera: post(api_v2 + '/api/camera/replace'),
  //使用管理查询指定配置信息
  getoptCamera: post(api_v2 + '/api/camera/getLiveInfo'),

  // 批量配置摄像头硬编解码
  batchUpdateHardConfig: post(api_v1 + '/api/camera/batchUpdateHardConfig'),

}

// 开关主题
export const switchTheme = post(api_v2 + '/api/agent/switchTheme')
export const getSwitchConfigs = get(api_v1 + '/api/customer/getSwitchConfigs')
export const switchFra = get(api_v1 + '/api/customer/switchFra')
export const switchInfraredNavAD = get(api_v1 + '/api/customer/switchInfraredNavAD')
// 查询已分配的所有盒子列表
export const getBoxList = post(api_v1 + '/api/solution_package/getBoxList')

// 下载已分配的所有盒子列表
export const getAssignedBoxDownload = get(api_v1 + '/api/solution_package/getAssignedBox/download')

// 广告机类型
export const apiEquipModelList = get(api_v1 + '/api/agent/getEquipModelList')

// 查询指定盒子的摄像头绑定列表
export const getCameraByBox = get(api_v1 + '/api/solution_package/getCameraByBox')

// 查询指定盒子的摄像头绑定列表-安防
export const getCameraByBox4Security = get(api_v1 + '/api/solution_package/getCameraByBox4Security')

// 批量绑定摄像头（安防）
export const bindCamera4Security = post(api_v1 + '/api/solution_package/bindCamera4Security')

// 解绑摄像头（安防）
export const unbindCamera4Security = post(api_v1 + '/api/solution_package/unbindCamera4Security')
