import { orgShopTree } from 'src/api/legacy'

function lowerRequest(func, delay) {
  let time
  return function (...args) {
    if (time) {
      clearTimeout(time)
    }
    time = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
export default {
  data() {
    return {
      treeIsShow: false, //树显示
      orgcode: '', //默认code
      checkedName: '', //默认组织树名
      getTreeOrgcode: '', //选中树组织数据
      shopsTree: [],
      defaultProps: {
        label: 'name',
        children: 'sublist',
      },
      arrData: [],
      searchOrg: '',
      expanded: [],

      searchStore: '',
    }
  },
  watch: {
    searchOrg(val) {
      lowerRequest(this.$refs.tree.filter(val), 1000)
      if (val === '') {
        const nodes = this.$refs.tree.store._getAllNodes()
        nodes.forEach((n) => {
          if (n.data.level === 0) {
            n.expanded = true
          } else {
            n.expanded = false
          }
        })
      }
    },
    searchStore(val) {
      lowerRequest(this.$refs.shop.filter(this.shopRange), 1000)
      if (val === '') {
        const nodes = this.$refs.shop.store._getAllNodes()
        nodes.forEach((n) => {
          if (n.data.level === 0) {
            n.expanded = true
          } else {
            n.expanded = false
          }
        })
      }
    },
  },
  methods: {
    //搜索门店
    filterNodeStore(value, data, node) {
      if (!value) {
        return true
      }

      return this.getReturnNodeStore(value, data, node)
    },
    getReturnNodeStore(value, data, node) {
      const isCurrentOrg = data.prmType === 2 && data.orgcode.indexOf(value) === 0

      if (isCurrentOrg) {
        let isPass =
          node.data && node.data.name && node.data.name.toLowerCase().indexOf(this.searchStore.toLowerCase()) > -1
        if (isPass) {
          return true
        }
        if (node.level != 1 && node.parent) {
          return this.getReturnNodeStore(value, data, node.parent)
        }
      }
      return false
    },

    //过滤tree
    filterNode(value, data, node) {
      if (!value) {
        return true
      }
      let level = node.level
      let _array = [] //这里使用数组存储 只是为了存储值。
      this.getReturnNode(node, _array, value)
      let result = false
      _array.forEach((item) => {
        result = result || item
      })
      return result
    },
    getReturnNode(node, _array, value) {
      const reg = new RegExp(value, 'i')
      let isPass = node.data && node.data.name && reg.test(node.data.name)
      isPass ? _array.push(isPass) : ''
      this.index++
      if (!isPass && node.level != 1 && node.parent) {
        this.getReturnNode(node.parent, _array, value)
      }
    },
    // 判断传入的节点是不是选中节点的子节点
    checkBelongToChooseNode(value, data, node) {
      constlevel = node.level
      // 如果传入的节点本身就是一级节点就不用校验了if(level ===1) {returnfalse; }
      // 先取当前节点的父节点
      letparentData = node.parent
      // 遍历当前节点的父节点
      letindex = 0
      while (index < level - 1) {
        // 如果匹配到直接返回
        if (parentData.data.label.indexOf(value) !== -1) {
          returntrue
        }
        // 否则的话再往上一层做匹配
        parentData = parentData.parent
        index++
      }
      // 没匹配到返回false
      return false
    },
    checkFilter() {
      this.$refs.tree.filter('')
    },
    getOrgcode() {
      if (this.getTreeOrgcode) {
        this.orgcode = this.getTreeOrgcode.orgcode
        this.checkedName = this.getTreeOrgcode.name
        this.overviewInit()
      }
      this.treeIsShow = false
    },
    handleNodeClick(data) {
      this.getTreeOrgcode = data
    },
    treeClick() {
      //显示树组织
      this.searchOrg = ''
      this.treeIsShow = true
    },
    hideAll() {
      //隐藏树组织
      this.treeIsShow = false
    },
    OrgShopTree() {
      //获取树文件数据
      return orgShopTree().then((res) => {
        if (res.data && res.status === 0) {
          this.arrData = [res.data]
          this.shopsTree = [res.data]
          this.orgcode = res.data.orgcode
          this.checkedName = res.data.name
          this.expanded.push(this.orgcode)
        }
      })
    },
    async getTreeData() {
      await this.OrgShopTree()
    },
    filterTree(nodes, predicate, childKey = 'sublist') {
      if (!nodes || !nodes.length) return void 0
      const children = []
      for (let node of nodes) {
        node = Object.assign({}, node)
        const sub = this.filterTree(node[childKey], predicate, childKey)
        if ((sub && sub.length) || predicate(node)) {
          sub && (node[childKey] = sub)
          children.push(node)
        }
      }
      return children.length ? children : void 0
    },
  },
  computed: {},
  mounted() {
    document.addEventListener('click', (e) => {
      if (
        this.$refs.shopBox &&
        !this.$refs.shopBox.contains(e.target) &&
        this.$refs.treeBox &&
        !this.$refs.treeBox.contains(e.target)
      ) {
        this.treeIsShow = false
      }
    })
  },
}
