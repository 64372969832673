<template>
  <div class="app-container">
    <div>
      <div class="titletop">
        <div class="session1">
          <div class="shopBox" ref="shopBox" @click="treeClick">
            <i>组织：</i>
            <input type="text" readonly name="" id="" :value="checkedName" />
            <span class="el-tree-node__expand-icon el-icon-caret-right expanded"></span>
          </div>
          <div class="treeBox" ref="treeBox" v-show="treeIsShow">
            <el-input
              placeholder="请输入组织名称搜索"
              v-model="searchOrg"
              @change="searchOrg"
              class="input-with-select"
            >
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
            <el-tree
              node-key="orgcode"
              :data="shopsTree"
              :props="defaultProps"
              ref="tree"
              :default-expanded-keys="[orgcode]"
              :filter-node-method="filterNode"
              :expand-on-click-node="false"
              highlight-current="true"
              @node-click="handleNodeClick"
            >
            </el-tree>
            <div class="dialogBtn pt20 mb20 borderTop">
              <button @click="getOrgcode" type="button" class="ml20 ag-model-btn blue fts mr5">确定</button>
              <button @click="hideAll" type="button" class="ag-model-btn gray fts">取消</button>
            </div>
          </div>
          <span class="ml20">用户状态：</span>
          <el-select v-model="userStatus" @change="searchList">
            <el-option :value="null" label="全部"></el-option>
            <el-option :value="0" label="已停用"></el-option>
            <el-option :value="1" label="使用中"></el-option>
          </el-select>
          <span class="ml20">账号来源：</span>
          <el-select v-model="accountSource" @change="searchList">
            <el-option :value="null" label="全部"></el-option>
            <el-option :value="0" label="外部创建"></el-option>
            <el-option :value="1" label="内部创建"></el-option>
          </el-select>
          <br />
          <el-input
            placeholder="请输入用户名/手机号/邮箱"
            v-model="seachValue"
            @change="searchList"
            class="searchInput mt20 mr10"
            style="width: 250px"
          >
            <el-button class="searchbtn" slot="append" icon="el-icon-search"></el-button>
          </el-input>
          <el-button type="text" @click="reset" class="ml30">重置</el-button>
        </div>
      </div>
      <div class="bottom-btn">
        <div class="operation flex flex-between">
          <el-button
            @click="newMng"
            v-if="vm.PowerData.nrcp_user_add !== 2"
            :disabled="vm.PowerData.nrcp_user_add === 1"
            icon="el-icon-user"
            type="primary"
          >
            新建用户
          </el-button>
          <el-button @click="downloadFile"><i class="fa fa-download fhd"></i></el-button>
        </div>
      </div>
      <div class="integratable">
        <el-table :data="tableData" @sort-change="sortChange" row-key="uid">
          <!-- <el-table-column prop="loginName" align="left" show-overflow-tooltip label="用户名">
      </el-table-column>
      <el-table-column prop="staffCode" sortable='custom' align="right" show-overflow-tooltip label="员工编号">
      </el-table-column>
      <el-table-column prop="name" align="left" show-overflow-tooltip label="姓名">
      </el-table-column>
      <el-table-column prop="mobile" align="right" show-overflow-tooltip label="手机号">
      </el-table-column>
      <el-table-column prop="email" align="left" show-overflow-tooltip label="邮箱">
      </el-table-column>
      <el-table-column prop="roleNames" align="left" show-overflow-tooltip label="角色">
      </el-table-column>
      <el-table-column prop="orgNames" align="left" show-overflow-tooltip label="组织权限范围">
        <template slot-scope="scope">
          <span>{{scope.row.orgNames.join('、')}}</span>
        </template>
      </el-table-column> -->
          <el-table-column
            v-for="(item, index) in tableColumnList"
            :key="index"
            :prop="item.key"
            :label="item.label"
            :min-width="item.width"
          >
            <template slot-scope="scope">
              <div v-if="item.key === 'userStatus'">
                <span>{{ scope.row.userStatus ? '使用中' : '已停用' }}</span>
              </div>
              <div v-else-if="item.key === 'userType'">
                <span>{{ scope.row.userType ? '内部创建' : '外部创建' }}</span>
              </div>
              <div v-else-if="item.key === 'roleNameList'">
                <el-tooltip :content="scope.row.roleNameList.join('、')" placement="top" :hide-after="1000000">
                  <span>
                    {{ scope.row.roleNameList ? scope.row.roleNameList.join('、').slice(0, 10) + '...' : '' }}</span
                  >
                </el-tooltip>
              </div>
              <div v-else-if="item.key === 'orgNameList'">
                <el-tooltip :content="scope.row.orgNameList.join('、')" placement="top">
                  <span> {{ scope.row.orgNameList ? scope.row.orgNameList.join('、').slice(0, 10) + '...' : '' }}</span>
                </el-tooltip>
              </div>
              <div v-else>{{ scope.row[item.key] }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" min-width="100">
            <template slot-scope="scope">
              <!-- <el-button @click="toDetail(scope.row,'edit')" type="text" v-if="vm.PowerData.nrcp_user_update !== 2"
            :disabled="scope.row.usertype === 0 || vm.PowerData.nrcp_user_update === 1" size="small">编辑</el-button>
          <el-button v-if="vm.PowerData.nrcp_user_update !== 2" :disabled="vm.PowerData.nrcp_user_update === 1"
            @click="setDialog(scope.row.status,scope.row.uid)" type="text" size="small">{{isStep[scope.row.status]}}
          </el-button> -->
              <div class="flex alignCenter space-evenly">
                <el-button type="text" size="small" @click="toEdit(scope.row)" :disabled="!scope.row.userType">
                  编辑
                </el-button>
                <el-dropdown>
                  <span class="el-dropdown-link"> 更多<i class="el-icon-arrow-down el-icon--right"></i> </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="modifyPwd(scope.row)">
                      <el-button type="text" size="small">修改密码</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="changeUserStatus(scope.row)" :disabled="!scope.row.userType">
                      <el-button type="text" size="small" :disabled="!scope.row.userType">
                        {{ scope.row.userStatus ? '停用' : '启用' }}
                      </el-button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mt20 fr">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 30, 50]"
          :page-size="10"
          layout="total,sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-dialog :visible.sync="userStatusDialog" :title="`用户${userStatusTitle}`" width="30%">
        <span>是否确认{{ userStatusTitle }}用户{{ userStatusName }}？</span>
        <template slot="footer">
          <el-button @click="userStatusDialog = false">取 消</el-button>
          <el-button type="primary" @click="modifyUserStatus">确 定</el-button>
        </template>
      </el-dialog>
      <el-dialog title="修改密码" :visible.sync="modifyPwdDialog" width="30%" @close="$refs.form.resetFields()">
        <el-form :model="form" label-width="100px" ref="form" :rules="rules">
          <el-form-item label="新密码：" prop="pwd">
            <el-input v-model="form.pwd" type="password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" prop="checkPwd">
            <el-input v-model="form.checkPwd" type="password"></el-input>
          </el-form-item>
        </el-form>
        <template slot="footer">
          <el-button @click="modifyPwdDialog = false">取消</el-button>
          <el-button type="primary" @click="submitForm">确认</el-button>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import treemixin from 'src/mixins/tree'
import { queryUserList, updateUserStatus, updateUserPwd } from 'src/api/legacy'
import moment from 'moment'
import { vm } from 'src/utils/public'

var storeHttp = '/newretail'

export default {
  mixins: [treemixin],
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.pwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      orgcode: 0,
      seachValue: '',
      table: [],
      isStep: {
        0: '启用',
        1: '停用',
      },
      userStatus: null,
      accountSource: null,
      setDialogValue: '',
      uid: '',
      setDialogValue1: '',
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      tableColumnList: [
        {
          label: '用户名',
          key: 'uname',
          width: 120,
        },
        {
          label: '用户编号',
          key: 'id',
          width: 100,
        },
        {
          label: '姓名',
          key: 'name',
          width: 120,
        },
        {
          label: '手机号',
          key: 'phoneNumber',
          width: 100,
        },
        {
          label: '邮箱',
          key: 'email',
          width: 180,
        },
        {
          label: '角色',
          key: 'roleNameList',
          width: 150,
        },
        {
          label: '组织权限范围',
          key: 'orgNameList',
          width: 150,
        },
        {
          label: '账号来源',
          key: 'userType',
          width: 80,
        },
        {
          label: '用户状态',
          key: 'userStatus',
          width: 80,
        },
        {
          label: '创建时间',
          key: 'createTime',
          width: 120,
        },
      ],
      total: 0,
      userId: '',
      userState: true,
      userStatusTitle: '',
      userStatusName: '',
      userStatusDialog: false,
      modifyPwdDialog: false,
      form: {
        pwd: '',
        checkPwd: '',
      },
      rules: {
        pwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        checkPwd: [{ required: true, validator: validatePass, trigger: 'blur' }],
      },

      vm,
    }
  },
  computed: {
    downloadUrl() {
      return `${storeHttp}/api/user/downloadUserList?org=${this.orgcode}&userStatus=${
        this.userStatus === null ? '' : this.userStatus
      }&userType=${this.accountSource === null ? '' : this.accountSource}&keyword=${this.seachValue}&pageSize=${
        this.total
      }`
    },
  },
  mounted() {
    this.getTreeData().then(() => {
      let userMng = JSON.parse(sessionStorage.getItem('userMng'))
      if (userMng !== null) {
        this.orgcode = userMng.orgcode
        this.userStatus = userMng.userStatus
        this.accountSource = userMng.accountSource
        this.seachValue = userMng.seachValue
        this.checkedName = userMng.treeName
      }
      this.overviewInit()
      this.$refs.tree.setCurrentKey(this.orgcode)
    })
    window.onbeforeunload = () => {
      let obj = {
        orgcode: this.orgcode,
        treeName: this.checkedName,
        userStatus: this.userStatus,
        accountSource: this.accountSource,
        seachValue: this.seachValue,
      }
      sessionStorage.setItem('userMng', JSON.stringify(obj))
    }
  },
  methods: {
    searchList() {
      this.currentPage = 1
      this.getUserList()
    },
    getUserList() {
      let data = {
        org: this.orgcode,
        userStatus: this.userStatus === null ? '' : this.userStatus,
        userType: this.accountSource === null ? '' : this.accountSource,
        keyword: this.seachValue,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      }
      const loading = this.$loading({
        text: '加载中',
        target: document.querySelector('.el-table__body'),
      })
      queryUserList(data)
        .then((res) => {
          if (res && res.status === 0) {
            this.tableData = res.data.data
            this.total = res.data.totalSize
            this.tableData.forEach((item) => {
              item.createTime = moment(item.createTime).format('YYYY-MM-DD')
            })
          }
        })
        .finally(() => loading.close())
    },
    getOrgcode() {
      if (this.getTreeOrgcode) {
        this.orgcode = this.getTreeOrgcode.orgcode
        this.checkedName = this.getTreeOrgcode.name
        this.searchList()
      }
      this.treeIsShow = false
    },
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.getUserList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getUserList()
    },
    overviewInit() {
      //页面初始化
      this.getUserList()
    },
    sortChange(column) {
      //排序
      const { prop } = column
      const order = column.order === 'ascending' ? 1 : -1
      this.table.sort((a, b) => {
        let av = isNaN(Number(a[prop])) ? a[prop] : Number(a[prop])
        let bv = isNaN(Number(b[prop])) ? b[prop] : Number(b[prop])
        av = typeof av === 'undefined' ? '' : av
        bv = typeof bv === 'undefined' ? '' : bv
        if (typeof av === 'number' && typeof bv === 'number') {
          return av > bv ? order : -order
        } else if (typeof av === 'string' && typeof bv === 'string') {
          return av.toLowerCase() > bv.toLowerCase() ? order : -order
        } else if (typeof av === 'number' && typeof bv === 'string') {
          return order
        } else if (typeof av === 'string' && typeof bv === 'number') {
          return -order
        }
      })
    },
    newMng() {
      this.$router.push({ name: 'user-addUser', query: { type: 'new' } })
    },
    toDetail(row) {
      window.location.href = '../editLoginInfo/index.html?id=' + row.id
    },
    // 跳转到编辑页面
    toEdit(row) {
      this.$router.push({ name: 'user-edit', query: { id: row.id } })
    },
    changeUserStatus(row) {
      this.userStatusDialog = true
      this.userId = row.id
      this.userState = row.userStatus
      this.userStatusTitle = row.userStatus ? '停用' : '启用'
      this.userStatusName = row.uname
    },
    // 修改用户状态
    modifyUserStatus() {
      let data = {
        uid: this.userId,
        status: this.userState ? 0 : 1,
      }
      updateUserStatus(data).then((res) => {
        if (res && res.status === 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
          })
          this.userStatusDialog = false
          this.getUserList()
        }
      })
    },
    setDialog(val, uid) {
      //设置状态
      if (val === 0) {
        this.setDialogValue = 1
      } else {
        this.setDialogValue = 0
      }
      this.setDialogValue1 = val
      this.uid = uid
      const text = `确定要${this.isStep[this.setDialogValue1]}该账户吗?`
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.setOverDialog()
      })
    },
    setOverDialog() {
      //状态确认
      let data = {
        uid: this.uid,
        status: this.setDialogValue,
      }
      updateUserStatus(data).then((res) => {
        if (res && res.status === 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
          })
          this.getUserList()
        }
      })
    },
    reset() {
      this.currentPage = 1
      this.checkedName = 'ROOT'
      this.orgcode = 0
      this.userStatus = null
      this.accountSource = null
      this.seachValue = ''
      this.$refs.tree.setCurrentKey(this.orgcode)
      this.getUserList()
    },
    searchEven() {
      if (this.seachValue !== '') {
        this.getUserList()
      } else {
        this.table = []
        this.currentPage = 1
      }
    },
    downloadFile() {
      window.open(this.downloadUrl, '_top')
    },
    modifyPwd(row) {
      this.form.uname = row.uname
      this.modifyPwdDialog = true
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.updatePwd()
        } else {
          return false
        }
      })
    },
    updatePwd() {
      let data = {
        uname: this.form.uname,
        password: this.form.pwd,
      }
      updateUserPwd(data).then((res) => {
        if (res.data) {
          this.$message.success('密码修改成功')
          this.modifyPwdDialog = false
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.navtitle {
  width: 100%;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #d1d1d1;
  font-size: 14px;
}

.titletop {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0  0 20px 0;
  border-bottom: 1px solid #d1d1d1;
}

.el-table__header-wrapper {
  margin-top: 10px !important;
}

.seleSeach {
  width: 204px;
  height: 30px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.session1 {
  align-items: center;
}

.shopBox {
  display: inline-block;
}

/* 搜索框 */
.searchInput {
  width: 300px;
}

.searchInput .el-input-group--append .el-input__inner {
  width: 171px;
}

.searchInput .el-input-group__append {
  width: 30px;
  padding: 0px;
}

.searchInput .searchbtn {
  min-width: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
  height: 28px;
}
.bottom-btn {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  margin-top: 10px;
}
.treeBox {
  top: 40px !important;
  left: 45px !important;
}
.el-dropdown-link {
  font-size: 12px;
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>