import $ from 'jquery'
import Vue from 'vue'
import { getRefreshToken } from 'src/api/legacy'

var httpUrl = '/newretail'

//本地存储的 token 登陆名及密码
var headerData = JSON.parse(localStorage.getItem('headerData'))
var userArr = JSON.parse(localStorage.getItem('user'))

if (headerData == null || userArr == null || headerData == '' || userArr == '') {
  location.href = '/login/'
}
var logName = userArr[userArr.length - 1].username

export const vm = new Vue({
  data() {
    return {
      usersName: {
        name: logName,
      },
      dataTree() {
        return {
          props: {
            label: 'name',
            children: 'sublist',
          },
        }
      },
      shopS: [],
      shopsConTree: [],
      defultNum: '0',
      count: 1,
      checkedName: '',
      defultOrgcode: '',
      PowerData: {},
      defaultProps: {
        label: 'name',
        children: 'sublist',
      },
    }
  },
  created() {
    this.myInit()
    this.getRefreshToken()
    setInterval(() => {
      this.getRefreshToken()
    }, 1000 * 60 * 20)
  },
  methods: {
    getRefreshToken() {
      const refreshToken = localStorage.getItem('refreshToken')
      if (refreshToken) {
        return getRefreshToken({
          refreshToken,
        }).then((res) => {
          localStorage.setItem('accessToken', res.data.accessToken)
          localStorage.setItem('refreshToken', res.data.refreshToken)
        })
      }
    },
    isPoneAvailable(str) {
      let myreg = /^[1][3,4,5,7,8][0-9]{9}$/
      if (!myreg.test(str)) {
        return false
      } else {
        return true
      }
    },
    userExit() {
      if (localStorage.getItem('headerData')) {
        localStorage.removeItem('headerData')
      }
      sessionStorage.clear()
    },
    myAPI(data, url) {
      var promise = new Promise(function (resolve, reject) {
        $.ajax({
          headers: {
            token: headerData.token,
            uid: headerData.uid,
          },
          xhrFields: {
            withCredentials: true,
          },
          contentType: 'application/json; charset=utf-8',
          type: 'post',
          url: url,
          data: JSON.stringify(data), //
        }).done(function (res) {
          if (res.status == 3 || res.status == 12) {
            location.href = '/login/'
          } else if (res.status == 0) {
            resolve(res)
          } else {
            Vue.prototype.$alert(res.msg, '提示', {
              confirmButtonText: '确定',
              type: 'warning',
            })
            reject(res)
          }
        })
      })
      return promise
    },
    myAPI1(data, url) {
      var promise = new Promise(function (resolve, reject) {
        $.ajax({
          async: false,
          headers: {
            token: headerData.token,
            uid: headerData.uid,
          },
          xhrFields: {
            withCredentials: true,
          },
          contentType: 'application/json; charset=utf-8',
          type: 'post',
          url: url,
          data: JSON.stringify(data), //
        }).done(function (res) {
          if (res.status == 3 || res.status == 12) {
            location.href = '/login/'
          } else if (res.status == 0) {
            resolve(res)
          } else {
            Vue.prototype.$alert(res.msg, '提示', {
              confirmButtonText: '确定',
              type: 'warning',
            })
            reject(res)
          }
        })
      })
      return promise
    },
    getSpecialPower() {
      let url = httpUrl + '/api/current/getSpecialPower'
      this.myAPI({}, url).then((res) => {
        if (res.status === 0) {
          this.PowerData = res.data
        }
      })
    },
    myInit() {
      this.getSpecialPower()
    },
  },
})
